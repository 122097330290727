<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form method="post" @submit.prevent="onSubmit('frmGoal')" data-vv-scope="frmGoal" novalidate="novalidate">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('sidebar.goals') }}</h5>
            <div class="row">
              <div class="col-sm-4 form-group mb-4">
                <v-select class="md-form-control" name="year" label="Year" :clearable="false" :searchable="false" v-model="year" :options="yearList">
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.Year }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.Year }}
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="col-md-12 row">
              <div class="md-form col-md-4 row" v-for="(indicator, index) in indicators" :key="index">
                <div class="col-md-1"></div>
                <div class="col-md-8">{{indicator.name}}</div>
                <div class="col-md-2 text-right no-padding">
                  <label class="ui-switch no-margin small switch-solid switch-warning">
                    <input type="checkbox"  ref="modal_land" v-model="indicador" :value="indicator.id"><span></span>
                  </label>
                </div>
                <div class="col-md-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <button type="button" class="btn btn btn-outline-light pull-right" @click="onSaveGoal">
          Salvar
        </button>
        <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancelGoal">
          Cancelar
        </button>
      </div>
    </div>
    </form>
  </div>
</template>

<script>
// Components

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Services
import GoalService from '@/services/GoalService'

export default {
  name: 'GoalForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('basic.goal.title') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: true,
      fullPage: true,
      indicators: [],
      year: 2020,
      yearList: [2019, 2020],
      indicador: []
    }
  },
  watch: {
    year: val => {
      const _this = this
      _this.isLoading = true

      GoalService.getIndicators().then(res => {
        _this.indicators = res.data.data
        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      })
    }
  },
  components: {
    Loading,
    vSelect
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    // this.$store.dispatch('SetShowFilter', true)
    // this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this

    // Load basilares data
    GoalService.getIndicators().then(res => {
      _this.indicators = res.data.data
      _this.isLoading = false
    }).catch(() => {
      _this.isLoading = false
    })
  },
  computed: {
  },
  methods: {
    onCancelGoal (e) {
      var _this = this
      _this.$router.push({ name: 'GoalIndex' })
    },
    onSaveGoal (e) {
      this.submitted = true
    }
  }
}
</script>
